//** Tables // Styles from Corolla theme.
@table-margin-y: @global-margin;
@table-font-size: @global-font-size-small;
@table-line-height: 1.375;
@table-cell-padding: @global-margin;
@table-side-cell-padding: @focus-state-box-shadow-size;
@table-action-margin-x: @global-margin; // Same as to @table-cell-padding
@table-active-cell-bg-color: fade(@_black_color, 5%);
@table-active-cell-dark-bg-color: fade(@_black_color, 50%);
@table-th-font-weight: @global-font-weight-semibold;
@table-row-muted-avatar-opacity: .3;
@table-row-muted-avatar-opacity-dark-theme: .6;

table {
  border-spacing: 0;
  border-collapse: collapse;
  font: inherit;
  font-size: @table-font-size;
  line-height: @table-line-height;
  margin: @table-margin-y -@table-side-cell-padding;
  width: 100%;
  vertical-align: middle;

  &.sticky-header {
    z-index: 55;
  }

  &.views-table {
    th > a.uk-button {
      white-space: nowrap;
    }
  }

  .uk-dropdown {
    font-size: unit(@dropdown-font-size, rem);
  }
}

th,
thead,
thead th {
  background-color: transparent;
  border: none;
  color: inherit;
}

tbody {
  background: none;
  border: none;
}

caption,
th,
td {
  [dir=ltr] & {
    text-align: left;
  }

  [dir=rtl] & {
    text-align: right;
  }
}

td,
th {
  border-bottom: none;
  margin: 0;
  padding: @table-cell-padding;
  vertical-align: middle;

  &.views-field-views-bulk-operations {
    .form-item {
      margin: 0;
    }
  }

  tr > & {
    &:first-child {
      padding-inline-start: @table-side-cell-padding;
    }

    &:last-child {
      padding-inline-end: @table-side-cell-padding;
    }

    &.views-field-views-bulk-operations,
    &.checkbox-cell {
      width: calc(@md-icon-size + @table-side-cell-padding);
      padding-inline-end: 0;
    }
  }
}

th {
  padding-top: 0;
  padding-bottom: @global-margin-small;
  font-weight: @table-th-font-weight;

  &.views-field-field-user-avatar {
    vertical-align: top;
  }
}

tr {
  background: none;

  &.even,
  &.odd {
    border-bottom: none;
  }

  &.drag {
    background-color: #f3fbfe;
  }

  &.drag-previous {
    background-color: #f8fdfe;
  }

  .responsive-table & td.active,
  &.odd td.active,
  &.even td.active,
  &:hover td.active {
    background-color: @table-active-cell-bg-color;

    .mtx-dark-theme & {
      background-color: @table-active-cell-dark-bg-color;
    }
  }

  &:first-child {
    td.region,
    td.module,
    td.container {
      border-top-width: 0;
    }
  }

  // Webkit work-around. Uncomment and adjust if you have borders on td.
  td:last-child {
    border-right: 0;
  }
}

.table-row-muted {
  td,
  .cell {
    &:not(.checkbox):not(.table-action-icon-cell) {
      color: @_grey_color;

      a {
        color: @_grey_color;
      }
    }

    .avatar img,
    .mtx-avatar {
      opacity: @table-row-muted-avatar-opacity;

      .mtx-dark-theme & {
        opacity: @table-row-muted-avatar-opacity-dark-theme;
      }
    }
  }
}

.table-action-icon {
  &-cell {
    padding-left: 0;
    padding-right: 0;

    .table-action-icon-wrapper {
      margin: 0 @table-action-margin-x;

      & > a {
        display: block;
      }
    }

    &:last-child {
      .table-action-icon-wrapper {
        margin-inline-end: 0;
      }
    }
  }

  &-cell&-with-text-cell {
    & > .table-action-icon-wrapper a {
      display: flex;
      align-items: center;

      .md-icon {
        min-width: @md-icon-size + @global-margin-mini;
      }
    }
  }
}

.table-sort-indicator {
  display: inline-block;
  position: relative;
  font-weight: @global-font-weight-semibold;

  .md-icon {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -(@md-icon-size / 2);
  }

  &.active {
    padding-inline-end: @md-icon-size;

    .md-icon {
      display: block;
      inset-inline-end: 0;
    }
  }

  &.asc {
    .md-icon:after {
      content: '\e5c7'; // md-arrow_drop_up
    }
  }

  &.desc {
    .md-icon:after {
      content: '\e5c5'; // md-arrow_drop_down
    }
  }
}

// Table vbo
.vbo-content-wrapper {
  .vbo-table-select-all-markup {
    display: none;
  }

  .vbo-hidden-actions {
    .mtx-quick-links {
      flex-wrap: nowrap;
    }

    &.is-hidden {
      visibility: hidden;
    }
  }
}

// Table drag & drop
.draggable a.tabledrag-handle {
  margin: 0 12px 0 0;
  padding: 0;
  float: left;
}

a.tabledrag-handle {
  .handle {
    background: none;
    font-size: 12px;
    line-height: 2;
    width: 2em;
    height: 2em;
    text-align: center;
    padding: 0;
    margin: 0;

    &:before {
      .fa-icon;
      content: '\f047'; // fa-arrows
    }
  }
}

span.tabledrag-changed {
  font-size: 13px;
  margin-left: 3px;
  color: #f00;

  table & {
    display: none;
  }
}

// Responsive table
.responsive-table {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 @table-side-cell-padding;
  margin: 0 -@table-side-cell-padding;

  .uk-touch &,
  &:hover {
    overflow-x: auto;
  }
}

// Calendar block.
.view-display-id-block_1 {
  thead {
    background: none;
  }

  .date-heading a {
    text-decoration: none;
  }

  tr {
    border: none;
  }
}

// Alert and alert scheme table
.view-alerts,
.view-alert-schemes {
  .views-field {
    &-field-alert-scheme-description {
      min-width: 15em;
    }

    &-author,
    &-field-alert-days,
    &-updater,
    &-full-name,
    &-full-name-1 {
      min-width: 10em;
    }
  }
}

// Locations table
.view-locations {
  .views-field {
    &-room,
    &-capacity,
    &-id {
      min-width: 5em;
    }

    &-location {
      min-width: 15em;
    }

    &-full-name,
    &-phone,
    &-mail {
      min-width: 10em;
      max-width: 20em;
    }
  }
}

// Products table
.view-products {
  .views-field {
    &-description {
      min-width: 15em;
    }

    &-cost,
    &-price,
    &-currency {
      min-width: 5em;
    }

    &-type {
      min-width: 7em;
    }

    &-courses {
      min-width: 10em;
    }
  }
}

// Certificates table
.view-certificates {
  .views-field {
    &-description {
      min-width: 15em;
    }

    &-type,
    &-changed-by,
    &-full-name {
      min-width: 10em;
    }
  }
}

// Course administration tables common
.view-alerts,
.view-alert-schemes,
.view-locations,
.view-products,
.view-certificates,
.view-certificate-templates,
.view-distributors {
  .views-field-counter {
    min-width: 3em;
  }

  .views-field-operations {
    padding: 0 @global-margin !important;

    .mtx-actions {
      display: inline-block;
      font-size: 1rem;
    }
  }
}

.view-alerts,
.view-alert-schemes,
.view-locations,
.view-products,
.view-certificates,
.view-certificate-templates,
.view-transactions {
  .views-field-title {
    min-width: 10em;
  }
}

.view-alert-schemes,
.view-locations,
.view-certificates,
.view-certificate-templates {
  .views-field-used-in {
    min-width: 20em;
  }
}

.view-alerts,
.view-alert-schemes,
.view-certificates,
.view-certificate-templates {
  .views-field-changed {
    min-width: 10em;
  }
}

// Organizations list table
.view-organizations {
  .view-filters {
    [class*="views-widget-filter-"] {
      margin-bottom: @global-margin;
    }

    .date-field-clear {
      [dir=ltr] & {
        margin: 0 @global-margin-large 0 @global-margin-small;
      }

      [dir=rtl] & {
        margin: 0 @global-margin-small 0 @global-margin-large;
      }
    }
  }

  .views-field {
    &-tid {
      min-width: 3em;
    }

    &-organization {
      min-width: 22rem;
      max-width: 22rem;

      .mtx-list-item > a {
        display: inline-block;
        margin: @global-margin 0;
        font-weight: @global-font-weight-normal;
        text-decoration: none;

        .title {
          .text-ellipsis-box(2);
          text-decoration: @base-link-text-decoration;
        }

        &:hover,
        &:focus {
          .title {
            text-decoration: @base-link-hover-text-decoration;
          }
        }
      }

      .suborganization-path {
        .text-ellipsis-box(1);
      }
    }

    &-description {
      [data-show-more] p {
        margin: 0;
      }
    }

    &-organization-users,
    &-field-organization-manager {
      min-width: 10em;
    }

    &-changed {
      min-width: 10em;
    }

    &-distributor,
    &-field-customer-id,
    &-field-company-code,
    &-description {
      min-width: 12em;
      max-width: 18em;
    }
  }
}

// Distributors list table
.view-distributors {
  .views-field {
    &-email,
    &-phone {
      min-width: 15em;
    }

    &-title,
    &-organizations {
      .break-word();
      min-width: 17em;
    }
  }
}

// Transactions table.
.view-transactions {
  .views-field {
    &-id,
    &-uid,
    &-nid,
    &-changed,
    &-title-1,
    &-activity-id {
      min-width: 10em;
    }

    &-total {
      min-width: 7em;
    }

    &-type,
    &-payment-method,
    &-status {
      min-width: 5em;
    }

    &-uid,
    &-nid {
      .break-word();
    }
  }

  .views-field-status {
    padding: 0 @global-margin;

    .status {
      display: block;
      position: relative;

      span {
        display: inline-block;
      }

      &:after {
        .material-icon();
        content: '\e5c5'; // md-arrow_drop_down
        position: absolute;
        top: 50%;
        margin-top: -.75rem;
      }

      [dir=ltr] & {
        span {
          padding: 0 1.625rem 0 0;
        }

        &:after {
          right: .125rem;
        }
      }

      [dir=rtl] & {
        span {
          padding: 0 0 0 1.625rem;
        }

        &:after {
          left: .125rem;
        }
      }
    }

    .mtx-actions.uk-open {
      .status:after {
        content: '\e5c7'; // md-arrow_drop_up
      }
    }
  }
}

// Parts view table.
.view-parts {
  .views-field {
    vertical-align: top;

    &-part-number,
    &-pack-size,
    &-price {
      width: 8rem;
    }

    &-part-tags {
      width: 12rem;
    }

    &-field-operations {
      width: 2rem;
    }

    &-part-image {
      width: @list-icon-size;
      padding-left: 0;
      padding-right: 0;

      .icon,
      img {
        margin: 0 !important;
      }
    }

    &-name {
      [dir=ltr] & {
        padding-left: @global-margin-large;
      }

      [dir=rtl] & {
        padding-right: @global-margin-large;
      }
    }
  }

  .parts-description {
    padding-bottom: @global-margin-large;
  }
}

.mtx-files-kb-usage,
.mtx-regulatory-usage {
  td {
    vertical-align: top;

    img {
      margin: 0;
    }

    &.name {
      width: 50%;
      min-width: 20rem;
    }

    &.downloads {
      width: 8rem;
    }

    &.operations {
      width: 4rem;

      &:empty {
        display: none;
      }
    }
  }
}

// Reagents table.
.mtx-reagent-delivery-table {
  .cell {
    min-width: 7rem;
  }
}

// Views table fields word break and links.
.views-table {
  .views-field-title,
  .views-field-organization,
  .name {
    a {
      font-weight: @global-font-weight-semibold;
    }
  }

  .views-field {
    &-name,
    &-mail,
    &-organization {
      word-break: normal;
    }

    &-title,
    &-courses,
    &-activities,
    &-used-in,
    &-description,
    &-activity-id,
    &-field-alert-scheme-description {
      .break-word();

      & > a {
        display: inline-block;
      }
    }
  }
}

// System log event table.
.dblog-events-table {
  table {
    width: auto;
  }

  th:empty, td:empty {
    padding: 0 !important;
  }

  td.empty.message {
    padding: @global-margin-medium @global-margin !important;
  }
}

.dblog-event-table {
  table {
    word-break: break-word;
    width: auto;

    th {
      min-width: 7rem;
      padding: @global-margin;
    }

    th, td {
      vertical-align: top;
    }
  }
}

// Course history table.
.course-version-history-table {
  @version-cell-z-index: 1;

  font-size: @table-font-size;

  &.responsive-table {
    max-height: 50vh;
    overflow: auto;
    scrollbar-width: none; // Firefox
    margin: @table-margin-y 0;

    // Safari, Google Chrome, Edge
    &::-webkit-scrollbar {
      width: 0;
    }

    table {
      margin: 0;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: @version-cell-z-index + 1;
  }

  td {
    word-break: break-word;
  }

  .version-cell {
    position: sticky;
    min-width: 3rem;
    max-width: 6rem;
    // Fix table scrolling issue in Safari (partially)
    inset-inline-start: -@table-side-cell-padding;
    padding-inline-start: @table-side-cell-padding;
  }

  tbody .version-cell {
    z-index: @version-cell-z-index;
  }

  .date-cell,
  .author-cell,
  .modification-cell {
    min-width: 12rem;
    max-width: 15rem;
  }

  .modification-cell {
    a[target="_blank"] > .md-icon {
      font-size: 1.125rem;
      vertical-align: text-bottom;
    }
  }

  .comment-cell {
    min-width: 15rem;
  }
}

// Organization settings, Custom URL tab.
.mtx-organization-alias-table {
  .alias-wrapper {
    max-width: 50vw;
    word-break: break-word;
  }

  .alias-copy-button {
    min-width: @md-icon-size;
  }
}

.mtx-privacy-policy-table {
  .mtx-privacy-policy-language-links {
    display: flex;
    flex-wrap: wrap;
    gap: @global-margin;
  }

  .mtx-privacy-policy-language-link {
    color: inherit;
    display: flex;
    gap: @global-margin-mini;
    text-decoration: none;

    .md-icon {
      flex: none;
    }

    span {
      text-decoration: underline;
    }

    &:focus,
    &:hover {
      span {
        text-decoration: none;
      }
    }
  }

  // Cells.
  .publish-date-cell {
    min-width: 10rem;
  }

  .translations-cell {
    min-width: 20rem;
  }
}

