@field-description-font-size: @global-font-size-small;
@field-description-margin-top: .3125rem;
@field-type-number-width: 8rem;
@field-type-number-percent-sign-width: unit(30 / 16, rem);
@field-type-checkbox-radio-label-margin: @global-margin-small;
@field-type-checkbox-radio-siblings-margin: unit(@form-advanced-size + @field-type-checkbox-radio-label-margin + @global-margin-mini, rem);

@form-fields-group-margin-bottom: @global-margin-large;

@form-item-margin-top: @global-margin;
@form-item-margin-bottom: @global-margin;

// Forms
form {
  margin: 0;
  padding: 0;

  .description {
    color: @secondary_text_color;
  }

  &.uk-form {
    select.chosen:not([multiple]) {
      background-image: none;
    }
  }

  .uk-modal-content & > div > :first-child {
    margin-top: 0;
  }
}

input {
  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &[type='checkbox'],
  &[type='radio'] {
    & ~ label {
      margin-inline-start: @field-type-checkbox-radio-label-margin;
    }

    & ~ label ~ .description {
      margin-inline-start: @field-type-checkbox-radio-siblings-margin;
    }
  }

  // Hide useless input icon in Safari
  //noinspection CssInvalidPseudoSelector
  &:focus::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }

  // Reset styles for checkbox/radio.
  .mtx-api-switcher &,
  &.uk-hidden {
    &[type='checkbox'],
    &[type='radio'] {
      & ~ label,
      & ~ label ~ .description {
        margin-inline-start: 0;
      }
    }
  }

  // Field with icon.
  &.form-item-field-with-icon {
    padding-inline-end: calc(@global-margin-small + @md-icon-size + @form-padding-x) !important;

    & + .field-suffix {
      position: absolute;
      inset-inline-start: calc(100% - @md-icon-size - @form-padding-x);
      margin-top: calc((@form-height - @md-icon-size - @form-border-width) / 2);

      label {
        display: none;
      }
    }

    &[class*="form-item-field-icon"] {
      & + .field-suffix {
        label {
          display: flex;
        }
      }
    }

    &.form-item-field-icon-warning {
      & + .field-suffix {
        color: @text-warning-color;

        .md-icon:after {
          content: '\f8b6'; // md-error_outline
        }
      }
    }

    &.form-item-field-icon-success {
      & + .field-suffix {
        color: @text-success-color;

        .md-icon:after {
          content: '\f0be'; // md-check_circle
        }
      }
    }
  }
}

.form-item {
  margin-top: @form-item-margin-top;
  margin-bottom: @form-item-margin-bottom;

  &:first-child {
    margin-top: 0;
  }

  &.form-danger {
    input[type="text"],
    input[type="number"],
    textarea,
    select,
    .chosen-choices,
    .chosen-drop,
    .chosen-single {
      &, &:focus {
        border: 1px solid @form-danger-border;
      }
    }
  }

  .exposed-filters & {
    margin-top: 0;
    margin-bottom: @global-margin;
  }

  .description {
    font-size: @field-description-font-size;

    .md-icon {
      font-size: 1.25rem;
    }

    &:empty {
      margin: 0;
    }
  }

  .mtx-popup & .description {
    font-size: .8375em;
    padding: 0 0 .5em;
    line-height: 1.25;
  }

  .dependent-form-item {
    [dir=ltr] & {
      margin-left: @field-type-checkbox-radio-siblings-margin;
    }

    [dir=rtl] & {
      margin-right: @field-type-checkbox-radio-siblings-margin;
    }
  }
}

.form-item-checkbox-sibling {
  margin-inline-start: @field-type-checkbox-radio-siblings-margin;
}

.form-type-checkbox {
  &-related-sibling,
  & + .form-type-select {
    margin-inline-start: @field-type-checkbox-radio-siblings-margin;

    & + .form-type-select {
      margin-inline-start: @field-type-checkbox-radio-siblings-margin;
    }
  }
}

.receive_newsletters {
  margin-right: @field-type-checkbox-radio-siblings-margin;
}

fieldset {
  background: transparent;
  border: 1px solid #dadada;
  margin: 10px 0;
  padding: 10px;
  position: relative;

  fieldset:last-child {
    margin-bottom: 0;
  }

  div.with-legend {
    margin-top: 3em;
  }

  legend {
    display: block;
    font-weight: @global-font-weight-normal;
    padding: 0;
    border-radius: @global-border-radius;

    span.fieldset-legend {
      display: block;
      padding: 0 7px;
      word-break: break-word;
      word-wrap: break-word;
    }
  }

  .description {
    font-size: @field-description-font-size;
  }

  *:first-child + html & {
    background-color: transparent;
    background-position: 0 10px;
    padding: 0 10px 10px;

    & > .description,
    & .fieldset-wrapper .description {
      padding-top: 10px;
    }

    & legend,
    &.collapsed legend {
      display: inline;
    }
  }
}

html.js {
  fieldset {
    &.collapsed {
      min-height: 1.85em;
    }

    &.collapsible .fieldset-legend {
      background: none;
    }

    &.collapsible .fieldset-legend {
      padding: 2px 4px 2px 10px;
    }

    &.collapsible legend a {
      padding: 0 15px 0 0;
      color: #ffffff;
      background: url('@{global-images-path}forms/arrow-down-white.png') no-repeat right 6px;
    }

    &.collapsed legend a {
      background: url('@{global-images-path}forms/arrow-right-white.png') no-repeat right 6px;
    }

    &.collapsed .fieldset-wrapper,
    &.collapsed .action {
      display: none;
    }

    &.collapsed {
      border-width: 1px;
      margin-bottom: 15px;
    }

    &.collapsible div.fieldset-wrapper {
      margin-top: 3em;
    }
  }
}

.filter-form {
  input[type=text].disabled {
    pointer-events: none;
  }
}

// Textarea styles
textarea {
  resize: none;
  border-radius: @global-border-radius;
}

.resizable-textarea {
  width: 100%;

  textarea {
    margin-top: 0;
    resize: vertical;
    min-height: 8.5em;
  }

  .grippie {
    @height: 9px;

    height: @height;
    width: calc(100% - @form-border-width * 2);
    background: #eeeeee url(/misc/grippie.png) no-repeat center 2px;
    border-radius: 0 0 calc(@inputs-border-radius - 1px) calc(@inputs-border-radius - 1px);
    cursor: s-resize;
    margin-top: calc(-@height - @form-border-width);
    position: relative;
    left: @form-border-width;
  }
}

// Fields group.
.form-fields-group {
  padding: @global-margin-medium;
  background-color: @_grey_lighten_color;
  border-radius: @inputs-border-radius;
  margin-bottom: @form-fields-group-margin-bottom;

  .mtx-dark-theme & {
    background-color: @overlay-color-light;
  }
}

// Stripe fields.
.stripe-card-field {
  border: @form-border-width solid @form-border;
  border-radius: @global-border-radius;
  padding: @form-padding-y @form-padding-x;
  width: 100%;
  min-height: unit(@form-height - (@form-border-width / 16) * 2, rem) !important;

  iframe {
    height: 22px !important;
  }

  &.focus {
    border-color: @form-focus-border;
  }

  &.error {
    border-color: @form-danger-border;
  }
}

.error-stripe-card {
  margin-top: @field-description-margin-top;
  font-size: @field-description-font-size;
}

.form-item-field-tags-und .chosen-container {
  max-width: 420px;

  &.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    border-radius: @global-border-radius;
  }
}

.input-group {
  border-collapse: separate;
  display: table;
  position: relative;

  input {
    display: table-cell;
    float: left;
    margin-bottom: 0;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  .input-group-btn {
    display: table-cell;
    font-size: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 1%;

    .uk-button {
      margin-left: -1px;
    }
  }
}

// Style for import users form
#mtx-exchange-user-import-form {
  .form-item-pass,
  .notification-date-container {
    display: none;
  }

  .form-item-files-file {
    margin-top: 0;
  }
}

// Activity form
.activity-form {
  // Webinar.
  .activity-video-wrapper {
    .activity-video-type[data-uk-dropdown] {
      display: inline-block;

      .uk-dropdown {
        width: 100%;
      }
    }

    .activity-video-link-info {
      border-radius: @global-border-radius;
      padding: (@global-margin-medium * 2) @global-margin;
      text-align: center;
      background-color: @_grey_lighten_color;
      color: @_grey_dark_color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .mtx-dark-theme & {
        background-color: @overlay-color-light;
        color: inherit;
      }

      .md-icon {
        margin: 0 @global-margin-mini;
        vertical-align: bottom;
      }
    }
  }

  // Bundle.
  &.activity-bundle .form-item-courses {
    .search-choice {
      margin-top: .3125rem;
      margin-bottom: .3125rem;
      background-color: #00a8e6;
    }

    .chosen-choices {
      border-bottom-right-radius: @global-border-radius;
      border-bottom-left-radius: @global-border-radius;
    }

    .search-field {
      display: none;
    }

    .ajax-progress + .chosen-container .search-field {
      display: block;
    }
  }

  .activity-module > :first-child {
    .chosen-disabled {
      opacity: 1 !important;
    }

    .chosen-single {
      padding: 0;

      span {
        display: inline-block;
        width: auto;
        max-width: calc(100% - @global-margin-small * 2); // @choices-margin in chosen.less
        margin: .3125rem @global-margin-small; // @choices-margin in chosen.less
        border-radius: @global-border-radius;
        padding: @global-margin-small @global-margin; // @padding-x, @padding-y in chosen.less
        line-height: 1.3;
      }

      div {
        display: none;
      }
    }
  }

  .activity-module.with-dates {
    @media all and (min-width: @breakpoint-medium) {
      & > * {
        display: inline-block;
        vertical-align: text-top;
      }

      & > :first-child {
        min-width: 300px;
        max-width: 450px;

        [dir=ltr] & {
          margin-right: @global-margin-medium * 2;
        }

        [dir=rtl] & {
          margin-left: @global-margin-medium * 2;
        }
      }
    }
  }

  // Location.
  .mtx-location-timeline {
    display: none;

    @media all and (min-width: @breakpoint-medium) {
      display: inline-block;
      line-height: @form-height;
      margin-top: unit(@global-margin-large, em); // Label height.
    }
  }

  // Self enrollment.
  .activity-store {
    @media (max-width: @breakpoint-small-max) {
      .price-wrapper:first-of-type {
        margin-top: @global-margin;
      }
    }
  }

  .mtx-add-restriction-dropdown,
  .mtx-add-adaptive-module-dropdown {
    .mtx-custom-scrollbar {
      max-height: 300px;
    }
  }

  .activity-form-adaptive-module input[type='number'] {
    width: 6rem;
  }

  // Restrictions.
  .activity-form-restriction-params {
    @remove-btn-size: @md-icon-size;

    position: relative;
    width: 100%;

    .restrict-by-date {
      .chosen-container {
        min-width: 12rem;
      }
    }

    .restriction-remove-btn {
      border: 0;
      padding: 0;
      background-color: transparent;
      position: absolute;
      top: (@form-font-size * @global-line-height) + (@form-height - @md-icon-size) / 2;
      min-width: @md-icon-size;
      height: @md-icon-size;
    }

    [dir=ltr] & {
      padding-right: @remove-btn-size + @global-margin;

      .restriction-remove-btn {
        right: 0;
      }
    }

    [dir=rtl] & {
      padding-left: @remove-btn-size + @global-margin;

      .restriction-remove-btn {
        left: 0;
      }
    }
  }

  .mtx-add-restriction-dropdown-wrapper {
    margin-bottom: @global-margin-large;
  }

  .restrictions-conjunction {
    .form-type-radio + .form-type-radio {
      [dir=ltr] & {
        margin-left: @global-margin-large;
      }

      [dir=rtl] & {
        margin-right: @global-margin-large;
      }
    }
  }

  .courses-quantity-inline,
  .activities-quantity-inline {
    display: inline-block;
    width: 6rem;
    margin-left: @global-margin;
    margin-right: @global-margin;
  }

  // Registration details.
  .activity-registration-details {
    select[name*="field_latest_registration"] + .chosen-container {
      max-width: 100%;
    }

    .container-inline-date > .form-item {
      &,
      & > .form-item:last-child input {
        margin-inline-end: 0;
      }
    }
  }
}

// Activity form (signups seats limit per organization).
#organization-limit-seats-rows-container {
  // Similar to .mtx-conjunctive-rules-wrapper {}
  @seats-field-width: 7rem;
  @seats-field-margin: @global-margin-medium;
  @remove-btn-size: @md-icon-size;

  margin-bottom: @global-margin;

  .organization-item {
    position: relative;
    padding-inline-end: @button-html-entity-size;

    & > .form-item {
      margin-top: 0;
      margin-bottom: 0;
      margin-inline-end: @global-margin;
    }
  }

  .form-type-select {
    .form-type-select-max-width(@seats-field-margin + @seats-field-width);
  }

  .item-remove-btn {
    position: absolute;
    bottom: (@form-height - @button-html-entity-size) / 2;
    inset-inline-end: 0
  }
}

#mtx-activity-add-unknown-reservations-form {
  input {
    width: @field-type-number-width;
  }
}

// Activity form, Activity clone form, Training form
.activity-form-wrapper {
  .event-view {
    .description {
      margin-top: @field-description-margin-top;
    }

    .event-dates {
      margin-top: -@global-margin-small;

      .start-date,
      .start-time,
      .end-date,
      .end-time {
        margin-top: @global-margin-small;
      }
    }

    .webinar-url {
      margin-top: @global-margin;
      .input-video-icon;
    }
  }

  .form-simple-block {
    @timezone-field-margin-bottom: @global-margin-medium;

    .mtx-timezone-field {
      & > div {
        margin-top: 0;
      }

      & + .description {
        margin-top: @field-description-margin-top - @timezone-field-margin-bottom;
      }

      .chosen-container-single .chosen-single {
        margin: 0 0 @timezone-field-margin-bottom;
      }

      @media all and (min-width: @breakpoint-small) {
        width: 50%;
      }
    }

    @media all and (min-width: @breakpoint-medium) {
      // Event module case.
      .uk-width-medium-1-2 .mtx-timezone-field {
        width: 100%;
      }
    }
  }
}

// Alert scheme form
.add-alert-form {
  .chronology {
    @media all and (min-width: @breakpoint-small) {
      display: flex;
      flex-wrap: wrap;

      & > * {
        [dir=ltr] & {
          margin: 0 @global-margin-small @global-margin-small 0;
        }

        [dir=rtl] & {
          margin: 0 0 @global-margin-small @global-margin-small;
        }
      }
    }

    .form-item-days,
    .form-item-hours {
      width: @field-type-number-width;
    }
  }
}

// Search field on course administration pages
#views-exposed-form {
  &-products,
  &-alert-schemes,
  &-organization-users,
  &-locations,
  &-certificates,
  &-certificate-templates,
  &-organizations {
    &-default .views-exposed-widget {
      @media all and (max-width: @breakpoint-medium-max) {
        float: none;
        width: 100%;
        padding: 0;

        .form-type-textfield {
          max-width: 100%;
        }
      }
    }
  }
}

// Commerce settings, product creation forms common
.add-product-form,
.mtx-part-add-part-form {
  .commerce-settings-block + .commerce-settings-block {
    margin-top: @global-margin-large; // See .price-wrapper + .price-wrapper {}
  }

  .mtx-commerce-prices {
    .price-setting {
      width: @field-type-number-width;

      & + .field-suffix {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .form-item {
      & + .form-item {
        margin-top: 0;
      }

      &.form-item-price-credit-points-points {
        padding-top: @global-margin-small;
      }
    }

    .description {
      clear: both;
    }

    [dir=ltr] & {
      .form-item {
        float: left;
        margin-right: @global-margin-medium * 2;
      }

      .price-setting {
        margin-right: @global-margin;
      }
    }

    [dir=rtl] & {
      .form-item {
        float: right;
        margin-left: @global-margin-medium * 2;
      }

      .price-setting {
        margin-left: @global-margin;
      }
    }
  }
}

// Course prices and expires
.mtx-marketplace-course-commerce-settings-form,
.mtx-marketplace-course-upload-form {
  .price-setting,
  .expiration-setting {
    width: @field-type-number-width;
  }
}

// Form of course sharing to Marketplace
.mtx-marketplace-course-upload-form {
  .pager-icons {
    margin-top: @global-margin-large;

    .fa {
      margin: 0 (@global-margin / 2);
      color: @_grey_light_color;
    }
  }
}

// Commerce checkout form
.checkout-form-page {
  @organization-account-avatar-size: @global-size-medium;
  @organization-account-padding: @global-margin-medium;
  @organization-padding-x: (@form-advanced-size + @organization-account-padding * 2);

  // Item general view
  .order-confirmation &,
  .organization-checkout-form {
    .checkout-accounts-list > li {
      padding: @global-margin-medium 0;
    }

    .organization {
      .checkout-accounts-list > li {
        padding: @organization-account-padding;
      }
    }
  }

  .organization-checkout-form {
    .additional-products,
    .additional-info {
      & > :first-child {
        margin-top: @global-margin-large;
      }

      [dir=ltr] & {
        margin-left: @form-advanced-size + @organization-account-padding;
      }

      [dir=rtl] & {
        margin-right: @form-advanced-size + @organization-account-padding;
      }
    }
  }

  // Personal information
  .additional-info {
    input[type="text"]:not(.datepicker-calendar) {
      width: 100%;
    }

    .autoresizable-textarea {
      resize: none;
      min-height: unit(2 * @global-line-height + .5, em); // .5 = padding-top + padding-bottom
      max-height: unit(11 * @global-line-height + .5, em);
    }

    .coupon-wrapper {
      input.coupon {
        width: 100%;
      }
    }
  }

  // Enrollment by lab-manager
  .organization {
    position: relative;

    .title-wrapper {
      position: relative;
    }

    .organization-checkbox {
      position: absolute;
      top: 50%;
      margin-top: -(@form-advanced-size / 2);
    }

    .uk-accordion-title {
      margin-bottom: @global-margin-small;
      border-radius: @global-border-radius;
      padding: @global-margin-large @organization-padding-x;
      background-color: @_white_color;
      line-height: @global-line-height;
      font-size: @global-font-size;
      font-weight: @global-font-weight-semibold;

      .total {
        display: inline-block;
        border-radius: 50%;
        font-size: @global-font-size-small;
        line-height: 1.5rem;
        min-width: 1.5rem;
        text-align: center;
        transition: opacity .15s ease-in-out;

        &:empty {
          opacity: 0;
        }
      }

      .empty-text {
        font-weight: normal;
      }

      &:after {
        .material-icon();
        content: '\e5cf'; // md-expand_more
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: @_grey_dark_color;
      }

      &.uk-active:after {
        content: '\e5ce'; // md-expand_less
      }
    }

    .uk-accordion-content {
      display: none;
      padding: 0;

      &.uk-active {
        display: block;
      }
    }

    &.suborganization-empty {
      .uk-accordion-title {
        cursor: default;

        &:after {
          display: none;
        }
      }
    }

    [dir=ltr] & {
      .organization-checkbox {
        left: @organization-account-padding;
      }

      .uk-accordion-title:after {
        right: @organization-account-padding;
      }
    }

    [dir=rtl] & {
      .organization-checkbox {
        right: @organization-account-padding;
      }

      .uk-accordion-title:after {
        left: @organization-account-padding;
      }
    }
  }

  .suborganizations-header {
    .select-all {
      line-height: @base-h2-line-height; // See .group-title {}
    }
  }

  .suborganizations-wrapper {
    & > .suborganization-accordion > .title-wrapper + div {
      [dir=ltr] & {
        margin-left: @organization-padding-x - @organization-account-padding;
      }

      [dir=rtl] & {
        margin-right: @organization-padding-x - @organization-account-padding;
      }
    }
  }

  .contact-full-name {
    input[type=checkbox] {
      margin-top: unit((@organization-account-avatar-size - @form-advanced-size) / 2, rem);
    }

    .contact-avatar {
      width: @organization-account-avatar-size;
    }

    .account-info {
      .name {
        font-weight: @global-font-weight-semibold;
        margin-bottom: @global-margin-mini;
      }

      .email {
        color: @_grey_dark_color;
        font-size: @global-font-size-xsmall;
      }
    }

    [dir=ltr] & {
      input[type=checkbox] {
        margin-right: @global-margin-medium;
      }

      .contact-avatar {
        margin: 0 @global-margin-large 0 0;
      }
    }

    [dir=rtl] & {
      input[type=checkbox] {
        margin-left: @global-margin-medium;
      }

      .contact-avatar {
        margin: 0 0 0 @global-margin-large;
      }
    }
  }

  // Customer type and Payment method
  .payment-confirm .uk-form {
    font-size: @global-font-size-small;
    color: @_grey_dark_color;
  }

  .payment-types-block {
    .payment-type-unprocessed {
      display: none !important;
    }
  }

  .payment-type-icon {
    display: block;
    height: @md-icon-size;
    max-width: 100%;
  }

  .customer-types-additions {
    .additions > .field {
      margin-top: @global-margin;
    }

    @media all and (min-width: @breakpoint-xlarge) {
      .uk-grid + .uk-grid {
        margin-top: 0;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .form-item-customer-type,
    .form-item-payment-method {
      input {
        display: none;
      }

      input:checked:disabled + label {
        border-color: @form-disabled-color;
        box-shadow: 0 0 0 1px @form-disabled-color;
        cursor: default;
      }

      label {
        display: block;
        height: 100%;
        min-height: @button-large-height;
        margin: 0 !important;
        border: 1px solid @form-border;
        border-radius: @button-border-radius-large;
        padding: @button-large-padding-vertical @button-large-padding-horizontal;
        line-height: @button-large-line-height;
        cursor: pointer;
      }

      label > span {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .payment-types-block {
      .chosen-results {
        max-height: 5.75rem; // 92px calculated according to `li` height equal to 22px
      }
    }
  }
}

.on-demand-checkout-form {
  .uk-grid {
    & > .form-item,
    & + .form-item {
      margin-top: 0;
    }
  }
}

// Product creation form
.add-product-form {
  .price-wrapper + .price-wrapper {
    margin-top: @global-margin;
  }

  // Banner.
  .form-item-product-banner {
    .form-item {
      margin: 0;
    }
  }

  .mtx-product-banner-image-widget {
    @widget-size: 7.5rem;
    @icon-size: 1.75rem;

    position: relative;
    width: @widget-size;

    .mtx-default-avatar {
      width: 100%;
    }

    .image-preview {
      float: none;
      padding: 0;
      width: @widget-size;
      height: @widget-size;
      border-radius: @thumb-border-radius;
      background-color: @api-item-empty-bg;
      overflow: hidden;

      .mtx-dark-theme & {
        background-color: @overlay-color-darken;
      }

      img {
        .mtx-product-thumb-image();
      }
    }

    .image-preview-empty {
      position: relative;

      .md-icon {
        .position-center();
        font-size: 2rem;
      }
    }

    .mtx-api-image-upload,
    .mtx-api-image-remove {
      .md-icon {
        font-size: 1.125rem;
      }
    }

    .mtx-api-image-upload {
      display: inline-block;
      line-height: 2.5;

      .md-icon {
        font-size: @icon-size;
      }
    }

    .mtx-api-image-remove {
      position: absolute;
      top: 0;
      height: @widget-size;
      width: 100%;
      border-radius: @thumb-border-radius;
      text-align: center;
      background-color: @overlay-color-dark;
      opacity: 0;
      transition: @global-hover-transition;

      .md-icon {
        margin-top: (@widget-size - @icon-size) / 2;
        font-size: @icon-size;
        color: @_white_color;
      }
    }

    &:hover,
    &:focus-within {
      .mtx-api-image-remove {
        opacity: 1;
      }
    }

    .image-widget-data {
      // Hide default fields.
      .form-submit,
      .form-file,
      label {
        display: none;
      }
    }

    .file,
    .file-size,
    .field-type-text {
      display: none;
    }
  }
}

// Create coupons form
#mtx-coupon-add-form {
  .form-field-with-sign(~'.form-item-discount');

  .form-item-quantity input {
    width: unit(@field-type-number-width + @field-type-number-percent-sign-width, em);
  }
}

// Certificate form.
.mtx-certificate-form {
  .form-item {
    select.chosen {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      pointer-events: none;
      padding-top: .125rem; // Prevents select`s text bouncing while select is replaced with a chosen

      &::-ms-expand {
        display: none;
      }
    }

    &-test,
    &-video,
    &-scorm {
      display: none;
    }
  }

  &.template-mode {
    .mtx-api-pdf-preview {
      height: 60vh;
    }
  }

  &.certificate-mode {
    @media (min-width: @breakpoint-medium) and (max-width: @breakpoint-medium-max) {
      .certificate-form-container.uk-grid {
        width: 66.666%; // uk-width-medium-2-3
      }
    }

    @media (min-width: @breakpoint-large) {
      .certificate-appearance {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .certificate-expiration {
    &.certificate-expiration-disabled .form-item-expiration-enabled ~ * {
      display: none;
    }

    .certificate-expiration-type-related-details {
      padding-top: @form-item-margin-top;

      & > * {
        margin-top: 0;
        margin-bottom: @form-item-margin-top;
      }
    }

    .form-item-expiration-type {
      margin-inline-start: 0 !important;
    }

    [id*="expiration-date-wrapper"] {
      width: 100%;
    }
  }

  @media all and (max-width: @breakpoint-medium-max) {
    .certificate-appearance {
      margin-top: @global-margin;
    }
  }
}

.mtx-api-pdf-preview {
  height: 100%;
  background-color: @mtx-section-even-bg-color;
  min-height: 400px;
  max-height: 860px;

  iframe {
    height: 100%;
  }

  .mtx-dark-theme & {
    background-color: @mtx-section-even-bg-color-dark;
  }

  @media (max-width: @breakpoint-medium-max) {
    height: 60vh;
  }

  .pdf-preview-refresh {
    text-decoration: none;
  }

  .pdf-preview-text {
    color: @_grey_lighten_color;
    font-size: 3rem;
    font-weight: @global-font-weight-bold;
  }

  &-form {
    .form-field-with-sign(~'.form-item-top', false);
    .form-field-with-sign(~'.form-item-left', false);
  }

  &-column {
    display: flex;
    flex-direction: column;
  }
}

// Onboarding form.
.mtx-onboarding-rule-form {
  .mtx-custom-scrollbar {
    max-height: 300px;
  }
}

// Conjunctive rules.
// Certificate/Recertification, Onboarding forms.
.mtx-conjunctive-rules-wrapper {
  @percent-field-width: 7rem;
  @percent-field-margin: @global-margin-medium;

  @remove-btn-margin: @global-margin;

  @rule-and-padding-x: @global-margin-large;
  @rule-and-padding-y: @global-margin-medium;

  .mtx-conjunctive-rules-list {
    &.rule-or-added {
      background-color: @_white_color;
      padding: @rule-and-padding-y @rule-and-padding-x (@rule-and-padding-y - @global-margin);

      & > .rule-or {
        & > .form-type-select {
          margin-bottom: @global-margin-medium;
        }

        .item-rule {
          margin: @rule-and-padding-y -@rule-and-padding-x 0;
          padding: @global-margin @rule-and-padding-x 0;
        }

        & + .mtx-conjunctive-rules-item {
          margin-top: @rule-and-padding-y;
        }
      }

      & > .type-courses {
        .item-add-btn-wrapper {
          margin-bottom: @global-margin-small;

          & + .form-type-checkbox {
            margin-top: @global-margin;
            margin-bottom: @global-margin;
          }
        }
      }

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark;
      }
    }
  }

  .mtx-conjunctive-rules-item {
    .item-remove-btn {
      position: absolute;
      top: (@form-font-size * @global-line-height) + (@form-height - @button-html-entity-size) / 2;
      inset-inline-end: 0
    }

    .item-rule {
      .form-type-radio {
        [dir=ltr] & {
          margin: 0 @global-margin @global-margin 0;
        }

        [dir=rtl] & {
          margin: 0 0 @global-margin @global-margin;
        }
      }
    }

    .additional-options-wrapper {
      .form-type-checkbox {
        margin: 0 @global-margin @global-margin 0;
      }

      .specific-number-of-events {
        padding-bottom: 10px;
      }

      .specific-number-of-criteria-items-wrapper,
      .specific-number-of-events {
        display: flex;
        width: 100%;
        align-items: center;

        .form-item {
          margin: 0 @global-margin-small;
          width: auto;

          input {
            width: @field-type-number-width / 2;
          }
        }
      }

      &.specific-number-enabled {
        & ~ .item-rule:not(.uk-hidden) {
          margin-top: @global-margin-medium;
        }
      }
    }

    &.type-courses {
      .certificate-course-item > .form-item {
        margin-top: 0;
        margin-bottom: 0;

        &.disabled {
          color: @form-disabled-color;

          input.course-completion-percent[readonly] {
            border-color: @form-disabled-border;
            background-color: @form-disabled-background;
            .hook-form-disabled;
          }
        }
      }

      .form-type-select {
        .form-type-select-max-width(@percent-field-margin + @percent-field-width);
      }

      .certificate-course-item-removable .form-type-select {
        max-width: calc(100% - (@percent-field-margin + @percent-field-width + @remove-btn-margin + @button-html-entity-size));
      }

      .form-field-with-sign(~'.form-item[class*="percent"]', @percent-field-width - @field-type-number-percent-sign-width);

      .item-add-btn-wrapper {
        margin-bottom: @global-margin-medium;

        & + .form-type-checkbox {
          margin-top: -@global-margin-small;
          margin-bottom: @global-margin-medium;
        }
      }

      .item-remove-btn {
        position: static;
      }

      .form-type-select + .form-item {
        margin-inline-start: @percent-field-margin;

        & + .item-remove-btn {
          margin-inline-start: @remove-btn-margin;
        }
      }
    }

    &.type-practical,
    &.type-event {
      .item {
        .form-type-select-max-width(@remove-btn-margin + @button-html-entity-size);
        border-radius: @global-border-radius;
        margin-bottom: @global-margin;
        padding: @global-margin @global-margin-large;
        background-color: @_grey_lighten_color;

        .mtx-dark-theme & {
          background-color: @overlay-color-lighten;
        }
      }

      .item-remove-btn {
        top: @button-html-entity-size / 2;
      }
    }

    &.is-removable {
      position: relative;

      .form-type-select,
      .form-type-mtx-api-input,
      .mtx-suborganization-hierarchical-parent {
        .form-type-select-max-width(@remove-btn-margin + @button-html-entity-size);
      }

      .mtx-suborganization-hierarchical-parent {
        min-width: auto;
      }

      &.type-hire_date {
        .form-type-select {
          .form-type-select-max-width(@global-margin + @datepicker-date-input-width + @remove-btn-margin + @button-html-entity-size);
        }

        .form-type-select ~ div input {
          width: @datepicker-date-input-width;
        }
      }
    }
  }
}

// Create user form (Activities, Organization, Users)
.mtx-create-user-form {
  .schedule-user-notification {
    display: none;
  }

  &.mtx-site-settings-form {
    .form-wrapper + .form-wrapper {
      margin-top: @global-margin;
    }

    .form-item-status {
      label + .form-radios {
        margin-top: .188rem;
      }

      .form-item {
        margin: 0;
      }

      .form-item + .form-item {
        margin-top: @global-margin-small;
      }
    }

    @media (min-width: @breakpoint-large) {
      .form-type-select {
        width: 100%;
      }
    }
  }
}

// Practical assessment form
.practical-assessment-form {
  .learners-list {
    & > .mtx-list-item:first-child + .mtx-list-item {
      margin-top: 0;
    }

    .column-header {
      font-weight: @global-font-weight-semibold;
    }
  }

  .mark-all {
    margin: 0 @global-margin-small;
    vertical-align: middle;

    & > a {
      color: @_grey_color;

      &:hover,
      &:focus {
        color: @_grey_dark_color;
      }
    }
  }

  .ceu-cell {
    .form-item {
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  .learner-assessment-actions {
    .uk-dropdown {
      width: 100%;
    }
  }

  .explanation-text {
    margin: @global-margin-large 0;
    color: @_grey_darken_color;

    .mtx-dark-theme & {
      color: @_grey_lighten_color;
    }
  }
}

// Course related forms.
.mtx-combi-course-form {
  .form-actions-wrapper {
    position: fixed;
    bottom: 0;
    z-index: @mtx-course-el-link-z-index + 1;

    & > div {
      background-color: @mtx-section-even-bg-color;

      .mtx-dark-theme & {
        background-color: @mtx-section-even-bg-color-dark;
      }
    }

    .mtx-button-group {
      margin: (@global-margin-large - @button-group-margin-y) 0 @global-margin-large;
    }

    [dir=ltr] & {
      left: 0;
      right: 0;

      @media all and (min-width: @breakpoint-small) {
        left: @page-sidebar-closed-width;
        border-left: 1px inset fade(#000000, 3%); // See @page-sidebar-opened-box-shadow
      }
    }

    [dir=rtl] & {
      left: 0;
      right: 0;

      @media all and (min-width: @breakpoint-small) {
        right: @page-sidebar-closed-width;
        border-right: 1px inset fade(#000000, 3%); // See @page-sidebar-opened-box-shadow
      }
    }
  }
}

// Transactions filter form.
.view-transactions {
  & > div > .uk-align-medium-right {
    margin-left: 0;
    margin-right: 0; // rtl
  }

  .transactions-dates-filter {
    .form-item {
      display: inline-block;
      margin: 0 0 @global-margin;

      [dir=ltr] & {
        margin-right: @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @global-margin-small;
      }
    }
  }

  @media all and (min-width: @breakpoint-medium) {
    .transactions-dates-filter {
      label {
        display: inline-block;
        margin: 0;
      }

      [dir=ltr] & {
        float: left;
        margin-right: @global-margin;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @global-margin;
      }
    }

    .views-exposed-form {
      display: inline-block;
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    #mtx-api-filter {
      float: none;
      margin: 0 0 @global-margin;
    }
  }
}

// Re-activate forms
.mtx-part-add-part-form,
#mtx-marketplace-course-renew-form,
#mtx-marketplace-course-reactivate-form {
  .course-info {
    border: 1px solid @global-border-color;
    margin: 0;
    padding: @global-margin-medium;

    .image-wrapper {
      padding: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .info-wrapper {
      padding: @global-margin @global-margin-medium;

      h6 {
        margin-bottom: @global-margin-small;
      }
    }
  }

  .price {
    font-size: 1.5em;
    font-weight: @global-font-weight-semibold;

    &-type {
      color: @_grey_dark_color;
    }

    &-wrapper {
      text-align: right;
      padding: @global-margin 0;

      [dir=rtl] & {
        text-align: left;
      }
    }
  }

  @media screen and (max-width: @breakpoint-mini-max) {
    .course-info .info-wrapper {
      padding: @global-margin 0;
    }

    .price-wrapper {
      text-align: center;
      padding: @global-margin 0;

      [dir=rtl] & {
        text-align: center;
      }
    }
  }
}

#mtx-marketplace-course-renew-form .course-info {
  max-width: 40rem;
}

// @Todo: Create folder for Reports, move all related forms there.
//** Reports
.mtx-report-form-block {
  margin: @global-margin-large 0;
}

#h5p-content-node-form {
  .form-item-files-h5p {
    display: none;
  }
}

#mtx-activity-reactivate-signup-form {
  .form-item-reactivate-type label {
    margin: 0;
  }

  .expiration-date {
    display: none;
  }
}

#mtx-trial-role-change-trial-expiration-form {
  .form-item-expiration-date {
    margin-bottom: @global-margin-large;
  }
}

#mtx-user-legal-user-consent-settings-form {
  .form-item-mtx-user-legal-additional-popups-roles {
    display: none;
  }
}

.uk-form .form-item-inline {
  label {
    margin: 0;
    width: 30%;
    line-height: @form-height;

    [dir=ltr] & {
      .uk-float-left();
    }

    [dir=rtl] & {
      .uk-float-right();
    }
  }

  input[type="text"] {
    width: 70%;

    [dir=ltr] & {
      .uk-float-right();
    }

    [dir=rtl] & {
      .uk-float-left();
    }
  }

  .description {
    [dir=ltr] & {
      padding-left: 30%;
    }

    [dir=rtl] & {
      padding-right: 30%;
    }
  }
}

//** Event node form
.node-event-form {
  @media all and (min-width: @breakpoint-medium) {
    .search-field {
      input.default {
        color: @global-muted-color !important;
      }
    }
  }

  @media all and (max-width: @breakpoint-small-max) {
    .mtx-date-field {
      .date-padding > .form-item label {
        width: 100%;
        margin: 0;
      }
    }
  }

  .mtx-date-field + .mtx-timezone-field {
    .chosen-container-single .chosen-single {
      margin: 0 0 @global-margin-large;
    }
  }
}

.video-recording-chosen {
  @thumb-width: 180px;
  @thumb-height: 120px;

  .description {
    padding-bottom: @global-margin-medium;
  }

  .mtx-thumb {
    &,
    &-image {
      border-radius: @thumb-border-radius;
    }
  }

  .video-info {
    overflow: hidden;

    .title {
      margin-bottom: @global-margin-small;
      font-weight: @global-font-weight-semibold;
    }

    .details {
      color: @_grey_dark_color;
    }
  }

  @media all and (min-width: @breakpoint-small) {
    .video-thumb {
      width: @thumb-width;

      [dir=ltr] & {
        float: left;
        margin-right: @global-margin-large;
      }

      [dir=rtl] & {
        float: right;
        margin-left: @global-margin-large;
      }
    }

    .mtx-thumb {
      padding-top: unit(@thumb-height / @thumb-width * 100, %);
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .video-info {
      margin-top: @global-margin;
    }
  }
}

.video-conference-wrapper {
  [data-uk-dropdown] {
    display: inline-block;

    .uk-dropdown {
      min-width: 100%;
    }
  }

  label {
    margin-bottom: @global-margin-small;
  }

  .event-external-link {
    border-radius: @global-border-radius;
    padding: (@global-margin-medium * 2) @global-margin;
    text-align: center;
    background-color: @_grey_lighten_color;
    color: @_grey_dark_color;

    .mtx-dark-theme & {
      background-color: @overlay-color-light;
      color: inherit;
    }

    .md-icon {
      margin: 0 @global-margin-mini;
      vertical-align: bottom;
    }
  }
}

#mtx-file-kb-search-form,
#mtx-file-kb-my-bookmarks-search-form,
#mtx-file-kb-usage-search-form,
#mtx-regulatory-search-form,
#mtx-regulatory-usage-search-form,
#mtx-regulatory-my-bookmarks-search-form {
  .form-item-search {
    width: 340px;

    @media all and (max-width: @breakpoint-small-max) {
      width: 100%;
    }
  }
}

#platform-misc-footer-links-about-form {
  .form-item-link-url,
  #edit-about {
    display: none;
  }
}

#platform-misc-footer-links-terms-of-use-form {
  .form-item-link-url,
  #edit-terms-of-use {
    display: none;
  }
}

#platform-misc-footer-links-privacy-policy-form {
  .form-item-link-url,
  #edit-privacy-policy {
    display: none;
  }
}

.reagent-delivery-filters {
  .form-item-search {
    width: @exposed-input-width;
    .input-search-icon;
  }

  .filter-form {
    & > div {
      .clearfix();
    }

    [dir=ltr] & {
      .mtx-date-field,
      .container-inline-date > .form-item {
        margin-right: 0;
      }

      .mtx-date-field + div {
        margin-left: 0;
      }
    }

    [dir=rtl] & {
      .mtx-date-field,
      .container-inline-date > .form-item {
        margin-left: 0;
      }

      .mtx-date-field + div {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: @breakpoint-small-max) {
    #mtx-api-filter {
      margin: 0 0 @global-margin;
    }

    .filter-form {
      overflow: visible;
    }

    #mtx-api-filter,
    .filter-form {
      float: none;
    }

    .mtx-date-field {
      & > .form-item {
        width: auto;
      }

      [dir=ltr] & {
        float: left;

        & + div {
          float: right;
        }
      }

      [dir=rtl] & {
        float: right;

        & + div {
          float: left;
        }
      }
    }
  }

  @media all and (max-width: @breakpoint-mini-max) {
    .mtx-date-field {
      [dir=ltr] &,
      [dir=rtl] & {
        &,
        & + div {
          float: none;
        }
      }
    }
  }
}

// Organization/suborganization settings form.
.mtx-manage-organization-form {
  .field-name-field {
    &-organization-credit-points,
    &-organization-members-limit {
      input {
        width: @field-type-number-width + @field-type-number-percent-sign-width;
      }
    }

    &-organization-discount-date,
    &-organization-timezone {
      display: none;
    }
  }

  .form-field-with-sign(~'.field-name-field-organization-discount');

  .discount-expiration-fields {
    &.uk-flex-form-fields > * {
      margin-bottom: @global-margin-small;

      &:not(:last-child) {
        margin-inline-end: @global-margin-medium;
      }
    }
  }
}

// User register form.
.user-register-form {
  &:not(.mtx-create-user-form) {
    .uk-grid {
      @item-margin: @global-margin-large;

      margin-top: -@item-margin;

      & > * {
        margin: @item-margin 0 0;

        .form-item:not(.form-item-field-user-organization-und-children),
        &:empty {
          margin: 0;
        }
      }
    }
  }
}

// Editable inline field.
.mtx-editable-content {
  @action-size: @global-size-small;

  &-actions {
    @icon-size: 1.25rem;

    .action {
      color: @_grey_dark_color;

      .md-icon {
        font-size: @icon-size;
      }

      [dir=ltr] & {
        margin-right: @global-margin-mini;
      }

      [dir=rtl] & {
        margin-left: @global-margin-mini;
      }
    }

    .action.input-action {
      width: @action-size;
      height: @action-size;
      .button-border-radius(@action-size);
      padding: (@action-size - @icon-size) / 2;
      background-color: @_grey_lighten_color;
      transition: background-color .15s ease-in-out;

      .md-icon {
        vertical-align: baseline;
      }

      &.save {
        color: @global-text-color;
      }

      &:hover,
      &:focus {
        background-color: @_grey_light_color;
      }

      .mtx-dark-theme & {
        background-color: @overlay-color-light;

        &:hover,
        &:focus {
          background-color: @overlay-color-lighten;
        }
      }
    }
  }

  &-text {
    word-break: break-word;
    max-width: var(--course-version-comment-textarea-width);
  }

  &-text-field {
    .uk-form {
      textarea {
        font-size: @global-font-size-small;
        scrollbar-width: none; // Firefox

        // Safari, Google Chrome, Edge
        &::-webkit-scrollbar {
          width: 0;
        }
      }

      width: var(--course-version-comment-textarea-width);
    }
  }

  &.edit-mode {
    .mtx-editable-content-text,
    .mtx-editable-content-actions .action.edit {
      display: none;
    }
  }

  &.view-mode {
    .mtx-editable-content-text-field {
      display: none;
    }

    .mtx-editable-content-actions {
      .action.save,
      .action.cancel {
        display: none;
      }
    }
  }
}

// Organization subscription form.
.mtx-organization-manage-organization-subscription-form {
  .subscription-payment-method {
    & > .uk-form-label + .form-item {
      margin-top: @global-margin-mini;
    }
  }

  .form-field-with-sign(~'.subscription-price', @field-type-number-width, '', @field-type-number-percent-sign-width * 2);

  .subscription-price:after {
    content: attr(data-content);
    color: @_grey_dark_color;
  }
}

// Reports.
#mtx-report-training-statistics-export-form,
#mtx-report-certificate-report-form {
  .schedule-container,
  .completion-container,
  .form-item-include-self-paced,
  .form-item-activity,
  .certified-on-container,
  .expiration-container,
  .recertification-container {
    display: none;
  }

  .form-item-filter-by {
    .form-radios {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      .form-item {
        position: relative;
        min-height: @button-small-height;

        [dir=ltr] & {
          margin: 0 @global-margin-small @global-margin-small 0;
        }

        [dir=rtl] & {
          margin: 0 0 @global-margin-small @global-margin-small;
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        // .uk-button {}
        border: 1px solid @global-border-color;
        border-radius: @button-border-radius-small;
        transition: all .15s ease-in-out;

        &:before {
          display: none;
        }
      }

      label {
        position: relative;
        display: inline-block;
        min-height: @button-small-height;
        margin: 0;
        padding: @button-small-padding-vertical @button-small-padding-horizontal;
        font-size: @button-small-font-size;
        line-height: @button-small-line-height;
        cursor: pointer;
      }
    }
  }
}

.report-type-wrapper {
  position: relative;

  #edit-custom-fields {
    position: absolute;
    top: unit(@global-line-height, rem);

    [dir=ltr] & {
      right: -(@button-height + @global-margin-small);
    }

    [dir=rtl] & {
      left: -(@button-height + @global-margin-small);
    }
  }

  @media (max-width: @breakpoint-small-max) {
    #edit-custom-fields {
      [dir=ltr] & {
        right: 0;
      }

      [dir=rtl] & {
        left: 0;
      }
    }

    .form-item-report-template {
      [dir=ltr] & {
        margin-right: @button-height + @global-margin-small;
      }

      [dir=rtl] & {
        margin-left: @button-height + @global-margin-small;
      }
    }
  }
}

.report-template-form {
  &.mtx-site-settings-form {
    .form-wrapper {
      .form-wrapper + .form-wrapper {
        margin-top: 0;
      }
    }

    .form-type-select {
      width: 100%;
    }
  }

  .report-fields-list {
    .form-item {
      margin: 0 0 @form-item-margin-top;
    }
  }

  #edit-user-container,
  #edit-role-container,
  #edit-field-template-default > .form-type-checkbox {
    display: none;
  }
}

// User biography.
.mtx-user-biography-form {
  // Avatar.
  .mtx-user-avatar-widget(128px);
}

.mtx-activity-promote-invitation-add-form {
  #mtx-api-filter {
    float: none;
    margin: 0 0 @global-margin 0;
  }

  // Prevents jumping on load.
  .form-item-price-plan + .form-wrapper,
  .field-name-field-promote-remind-date,
  .field-name-field-remind-email-subject,
  [id*='field-remind-email-subject-tokens'],
  .field-name-field-remind-email-body {
    display: none;
  }
}

// Activity promote.
.mtx-activity-promote-form {
  // Prevents jumping on load.
  .field-name-field-promote-deadline-date,
  .field-name-field-promote-remind-date,
  .field-name-field-remind-email-subject,
  [id*='field-remind-email-subject-tokens'],
  .field-name-field-remind-email-body {
    display: none;
  }
}

.mtx-email-settings-limits-form {
  input[type='number'] {
    width: @field-type-number-width * 2;
  }
}

.node-teachers_announcement-form,
.activity-rules-form {
  .form-type-select {
    flex: 1;
  }

  .form-field-with-button {
    & > .form-item {
      flex: 1;
    }

    .uk-button-html-entity,
    .uk-button-link {
      position: relative;
      top: calc(@global-line-height * @global-font-size + @global-margin);
    }

    &-outside {
      @media all and (min-width: @breakpoint-large) {
        position: relative;

        .uk-button-html-entity,
        .uk-button-link {
          position: absolute;
          inset-inline-start: 100%;
          margin-inline-start: @global-margin;
        }
      }
    }
  }

  .mtx-conjunctive-rules-list {
    &.rule-or-added {
      padding-top: @global-margin-large;

      & > .rule-or {
        .item-rule {
          padding-block: @global-margin-large;
          margin-block: 0;
        }

        & + .mtx-conjunctive-rules-item {
          margin-top: 0;
        }
      }
    }
  }
}

.node-teachers_announcement-form {
  .announcement-criteria-list {
    .item-rule .form-item {
      margin: 0 !important;

      input[type=radio] {
        vertical-align: bottom;
      }
    }

    div:empty {
      display: none;
    }
  }
}

// Translatable field with accordion.
.uk-form .form-translatable-field-with-accordion {
  @icon-size: 1.5rem; // @md-icon-size

  position: relative;

  textarea,
  input {
    padding-inline-end: @icon-size + (@form-padding-x * 2);
  }

  .uk-accordion-title {
    position: absolute;
    top: @form-font-size * @global-line-height;
    display: block;
    margin: 0;
    padding: 0 @form-padding-x;
    line-height: @form-height;
    font-size: @icon-size;
    background: transparent;
    color: @_grey_dark_color;
    transition: color .2s ease-in;
    inset-inline-end: 0;

    &:hover,
    &:focus {
      color: @_grey_color;
    }

    .md-icon {
      font-size: inherit;
    }
  }

  .uk-accordion-content {
    margin: @form-item-margin-top 0;
  }

  .uk-modal-dialog & {
    @icon-size: 1.25rem;

    textarea,
    input {
      padding-inline-end: @icon-size + (@form-padding-x * 2);
    }

    .uk-accordion-title {
      font-size: @icon-size;
    }
  }
}

// Translatable field with standalone popup.
.form-translatable-field-with-standalone-popup {
  &.text-editor-wrapper {
    .form-type-mtx-text-editor {
      flex: 1;
    }
  }

  .form-translatable-field-with-standalone-popup-btn {
    margin-top: calc(@global-line-height * @global-font-size + @global-margin);
  }

  &.textfield-wrapper.no-label,
  &.text-editor-wrapper {
    .form-translatable-field-with-standalone-popup-btn {
      margin-top: @global-margin;
    }
  }
}

// Price fields.
#specify-price-fields-wrapper {
  .mtx-add-users-specify-price-details {
    display: none;
    margin-bottom: @global-margin-large;

    &[style*="display: block"] {
      display: flex !important;
    }

    &.uk-flex-form-fields > *:not(:last-child) {
      margin-inline-end: @global-margin;
    }

    & > .form-item {
      width: min-content;
      max-width: 100%;
    }

    .form-item-currency {
      min-width: 10rem;
    }

    .form-item-price-value {
      min-width: @field-type-number-width;
      max-width: @field-type-number-width;
    }

    .form-item-specify-price-credits-organizations {
      min-width: 14rem;
    }
  }
}
